// src/providers/QueryClientProvider.tsx
import React, { ReactNode } from 'react';

import { QueryClientProvider as ReactQueryClientProvider } from '@tanstack/react-query';

import { queryClient } from '../api';

interface QueryClientProviderProps {
  children: ReactNode;
}

const QueryClientProvider: React.FC<QueryClientProviderProps> = ({
  children,
}) => {
  return (
    <ReactQueryClientProvider client={queryClient}>
      {children}
    </ReactQueryClientProvider>
  );
};

export default QueryClientProvider;
