import React from 'react';
import type { ReactNode } from 'react';

import { HydrationBoundary } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import { HelmetProvider, Helmet } from 'react-helmet-async';

import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';

import ErrorBoundary from '../components/ErrorBoundary';
import { ThemeProvider as IDKThemeProvider } from '../contexts/ThemeContext';
// moved provider defs to their own files
import AuthProvider from '../providers/AuthProvider';
import LocalizationProvider from '../providers/LocalizationProvider';
import QueryClientProvider from '../providers/QueryClientProvider';
import ThemeProvider from '../providers/ThemeProvider';

type GetLayout = (page: ReactNode) => ReactNode;

type Page<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: GetLayout;
};

type MyAppProps<P = unknown> = AppProps<P> & {
  Component: Page<P>;
};

function App({ Component, pageProps }: MyAppProps) {
  const getLayout = Component.getLayout ?? ((page: ReactNode) => page);

  return (
    <AuthProvider>
      <QueryClientProvider>
        <HydrationBoundary state={pageProps.dehydratedState}>
          <HelmetProvider>
            <Helmet titleTemplate="%s | kalico" defaultTitle="kalico" />
            <LocalizationProvider>
              <ThemeProvider>
                {getLayout(<Component {...pageProps} />)}
                {/* <BottomNav /> */}
              </ThemeProvider>
            </LocalizationProvider>
          </HelmetProvider>
        </HydrationBoundary>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </AuthProvider>
  );
}

const GoogleTags = () => (
  <>
    <Script
      strategy="afterInteractive"
      src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
    />
    <Script id="google-analytics" strategy="afterInteractive">
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
          page_path: window.location.pathname,
        });
      `}
    </Script>
  </>
);

const withThemeProvider = (Component: any) => {
  // Obviously important, but I don't remember the difference betweeen this
  //  ThemeProvider and the one from MUI,.. TODO
  const AppWithThemeProvider = (props: JSX.IntrinsicAttributes) => {
    return (
      <ErrorBoundary>
        <IDKThemeProvider>
          <Component {...props} />
          <GoogleTags />
        </IDKThemeProvider>
      </ErrorBoundary>
    );
  };
  AppWithThemeProvider.displayName = 'AppWithThemeProvider';
  return AppWithThemeProvider;
};

export default withThemeProvider(App);
