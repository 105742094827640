import { green, grey } from '@mui/material/colors';

import { THEMES } from '../constants';

const customBlue = {
  50: '#e9f0fb',
  100: '#c8daf4',
  200: '#a3c1ed',
  300: '#7ea8e5',
  400: '#6395e0',
  500: '#4782da',
  600: '#407ad6',
  700: '#376fd0',
  800: '#2f65cb',
  900: '#2052c2 ',
};

const myOrange = '#FC7C74';
const myGray = '#9BB4B4';
const myDarkGray = '#424651';

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: 'light',
    primary: {
      main: myOrange,
      contrastText: '#FFF',
    },
    secondary: {
      main: '#424651',
      contrastText: '#FFF',
    },
    background: {
      default: '#F7F9FC',
      paper: '#FFF',
    },
    info: {
      main: customBlue[800],
    },
  },
  header: {
    color: grey[500],
    background: '#FFF',
    search: {
      color: grey[800],
    },
    indicator: {
      background: myOrange,
    },
  },
  footer: {
    color: grey[500],
    background: '#FFF',
  },
  sidebar: {
    color: grey[200],
    background: myDarkGray,
    header: {
      color: grey[200],
      background: myDarkGray,
      brand: {
        color: customBlue[500],
      },
    },
    footer: {
      color: grey[200],
      background: myDarkGray,
      online: {
        background: green[500],
      },
    },
    badge: {
      color: '#FFF',
      background: myOrange,
    },
  },
};

// can add more variants here for dark and light support
const variants: Array<VariantType> = [defaultVariant];

export default variants;

export type VariantType = {
  name: string;
  palette: {
    primary: MainContrastTextType;
    secondary: MainContrastTextType;
  };
  header: ColorBgType & {
    search: {
      color: string;
    };
    indicator: {
      background: string;
    };
  };
  footer: ColorBgType;
  sidebar: ColorBgType & {
    header: ColorBgType & {
      brand: {
        color: string;
      };
    };
    footer: ColorBgType & {
      online: {
        background: string;
      };
    };
    badge: ColorBgType;
  };
};

type MainContrastTextType = {
  main: string;
  contrastText: string;
};
type ColorBgType = {
  color: string;
  background: string;
};
