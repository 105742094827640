// src/providers/ThemeProvider.tsx
import React, { ReactNode } from 'react';

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import useTheme from '../hooks/useTheme';
import createTheme from '../theme';

interface ThemeProviderProps {
  children: ReactNode;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const { theme } = useTheme();
  return (
    <MuiThemeProvider theme={createTheme(theme)}>{children}</MuiThemeProvider>
  );
};

export default ThemeProvider;
