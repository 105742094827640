import React, { Component, ErrorInfo, ReactNode } from 'react';

import { Alert } from '@mui/material';
import * as Sentry from '@sentry/nextjs';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // we can log error or errorInfo to Sentry here.
    // errorInfo will contain the details
    // and stack trace which is better for debugging
    Sentry.captureException(errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Alert severity="error">
          Some generic error has occurred and the devs forgot to tell you how
        </Alert>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
