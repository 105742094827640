// src/providers/LocalizationProvider.tsx
import React, { ReactNode } from 'react';

import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface LocalizationProviderProps {
  children: ReactNode;
}

const LocalizationProvider: React.FC<LocalizationProviderProps> = ({
  children,
}) => {
  return (
    <MuiLocalizationProvider dateAdapter={AdapterDateFns}>
      {children}
    </MuiLocalizationProvider>
  );
};

export default LocalizationProvider;
