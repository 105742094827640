// src/providers/AuthProvider.tsx
import React, { ReactNode } from 'react';

import { Auth0Provider, AppState } from '@auth0/auth0-react';
import { Capacitor } from '@capacitor/core';
import { useRouter } from 'next/router';

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const router = useRouter();

  if (
    !process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL ||
    !process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID ||
    !process.env.NEXT_PUBLIC_AUTH0_BASE_URL_MOBILE ||
    !process.env.NEXT_PUBLIC_AUTH0_BASE_URL_WEB
  ) {
    throw new Error(
      'Please define the Auth0 environment variables inside .env.local'
    );
  }

  const onRedirectCallback = (appState: AppState | null | undefined) => {
    router.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL as string}
      clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string}
      useRefreshTokens={true}
      useRefreshTokensFallback={false}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: Capacitor.isNativePlatform()
          ? (process.env.NEXT_PUBLIC_AUTH0_BASE_URL_MOBILE as string)
          : (process.env.NEXT_PUBLIC_AUTH0_BASE_URL_WEB as string),
        // audience: 'your-api-audience',
        // scope: 'openid profile email',
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
